.wrapperStuff{
    width:100%;
    height:100vh;
}

.right{
    right:0px;
    position: absolute;
    width:100px;
    height:60px;
    top:50%;
}

.left{
    left:0px;
    position: absolute;
    width:100px;
    height:60px;
    top:50%;
}