@media only screen and (min-width: 1281px)
{
    nav ul{
        display:inline;
    }
    nav{
        display: block;
    }
}
@media only screen and (max-width: 1280px) 
{
    /* nav a,li,ul{
        display:none;
    } */
    nav a,li,ul{
        display:block;
        padding: 10 5px;
        margin:0;
    }
    nav{
        padding:0;
        margin: 0;
        margin-bottom:15px;
    }
    nav li{
        margin:0;
    }
}
@media only screen and (max-width: 1090px) 
{
    nav a,li,ul{
        display:block;
        margin:0 auto;
        padding:0 auto;
        width:100%;
    }

    nav li{
        padding:0;
        
    }
    nav a{
        width:calc(100% - 30px);
    }
    nav li{
        margin:0;
    }


}
@media only screen and (max-width: 950px) 
{
    nav li,a,ul{
        display: none;
    }

    .hamburger{
        display: block;
    }


    .publicLinks{
        width:100%;
    }
    .publicLinks li,a,ul{
        width:100%;
    }
}



/**
 *  This is for tablets
**/
@media only screen and (max-width: 47em) 
{
    html{
        min-height: 100%;
        width:100%;
        padding:0;
        margin:0;
    }
    
    body{
        min-height: 100vh;
        width:100%;    
        padding:0;
        margin:0;
        background-color: var(--main_bg_color);
        color: var(--main_text_color);
    }
    
    header{
        height: var(--header_height);
        width:100%;    
        padding:0;
        margin:0;
        background-color: var(--light_bg_color);
        color: var(--text_on_light_color);
    }
    
    main{
        min-height: calc(100vh - var(--header_height) - var(--footer_height));
        width:100%;
        float:left;
    }
    
    aside{
        min-height: calc(100vh - var(--header_height) - var(--footer_height));
        width:100%;
        background-color: var(--main_accent_color);
        float:left;
    }
    
    footer{
        height: var(--footer_height);
        width:100%;    
        padding:0;
        margin:0;
        background-color: var(--light_bg_color);
        color: var(--text_on_light_color);
        float:left;
    }
    
.smallcard {
  padding: 15px;
  margin: 0;
  width: calc(100% - 30px);
  height: 75vh;
  max-height: 75vh;
  float: left;
}
nav li{
    margin:0;
}


/* .prizeHint{
    position: absolute;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color:white;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
} */

}

/**
 *  This is for phones
**/
@media only screen and (max-width: 30em) 
{
    html{
        min-height: 100%;
        width:100%;
        padding:0;
        margin:0;
    }

    body{
        min-height: 100vh;
        width:100%;    
        padding:0;
        margin:0;
        background-color: var(--main_bg_color);
        color: var(--main_text_color);
    }

    header{
        height: var(--header_height);
        width:100%;    
        padding:0;
        margin:0;
        background-color: var(--light_bg_color);
        color: var(--text_on_light_color);
    }

    main{
        min-height: calc(100vh - var(--header_height) - var(--footer_height));
        width:100%;
        float:left;
    }

    aside{
        min-height: calc(100vh - var(--header_height) - var(--footer_height));
        width:100%;
        background-color: var(--main_accent_color);
        float:left;
    }

    footer{
        height: var(--footer_height);
        width:100%;    
        padding:0;
        margin:0;
        background-color: var(--light_bg_color);
        color: var(--text_on_light_color);
        float:left;
    }
    nav li{
        margin:0;
    }
}